import { useSelector } from 'react-redux';
// mui
import { Stack, Typography } from '@mui/material';
// redux
import { selectSearchLeadData } from '../../redux/search/searchSlice';
// components
import SearchLead from '../../components/search-lead/SearchLead';

const SearchTable = () => {
  const searchLeads = useSelector(selectSearchLeadData);

  return (
    <Stack>
      {searchLeads.length > 0 ? (
        <Stack sx={{ mb: 3 }} direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'} gap={2}>
          <Typography variant="h4">Your Recent Searches</Typography>
        </Stack>
      ) : (
        <></>
      )}
      <Stack sx={{ marginBottom: '30px' }} gap={5}>
        {searchLeads.map((searchLead) => (
          <SearchLead
            key={searchLead?.request_id}
            requestId={searchLead?.request_id}
            searchTerm={searchLead?.searchTerm}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default SearchTable;
