import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
// mui
import { Box, Button, Stack, Collapse, Typography } from '@mui/material';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
// components
import CountryDropdown from '../country-dropdown/CountryDropdown';
import TagsInput from '../tags-input/TagsInput';
// functions
import { privateRequest } from '../../utils/apiRequest';

const FilterComponent = ({ requestId }) => {
  const [series, setSeries] = useState([100]);
  const [isFilterationModalVisible, setIsFilterationModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [country, setCountry] = useState('');
  const [keyWords, setKeyWords] = useState([]);

  const [keyWordChartError, setKeyWordChartError] = useState('');
  const [countryChartError, setCountryChartError] = useState('');

  const [existsSeries, setExistsSeries] = useState([100, 100]);
  const [missingSeries, setMissingSeries] = useState([0, 0]);

  const [seriesCountry, setSeriesCountry] = useState([]);

  const optionsKeywords = {
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        // hollow: {
        //   size: '70%',
        // },
      },
    },
    // labels: keyWords.length > 0 ? [keyWords[0]] : ['Filter'],
    labels: ['Filter'],
  };
  const optionsCountry = {
    colors: ['#58D68D', '#EC7063'],
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: ['Country', 'Ph No.'],
    },
    legend: {
      position: 'right',
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  };

  useEffect(() => {
    console.log('EXISTSS CHECK Missing', missingSeries);
    setSeriesCountry([
      {
        name: 'Exists',
        data: existsSeries,
      },
      {
        name: 'Missing',
        data: missingSeries,
      },
    ]);
  }, [missingSeries, existsSeries]);

  const onChange = (_, newValue) => {
    // this sets country
    setCountry(newValue);
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const selectedKeywords = (tags) => {
    console.log('Parent Got : ', tags);
    setKeyWords(tags);
  };

  const filter = async (requestId) => {
    const body = {
      request_id: requestId,
      keywords: keyWords.map((keyword) => keyword.tag),
      countryCode: country?.id,
      dialCode: country?.dialCode,
    };

    //! for keywords
    if (keyWords.length > 0) {
      try {
        const response = await privateRequest.post(`/api/quota/keywords-analysis/`, body);
        const percentage = (response?.data?.FilteredCount / response?.data?.RawCount) * 100;
        setSeries([percentage]);
        setKeyWordChartError('');
      } catch (error) {
        console.log(error);
        setKeyWordChartError('some error occured');
      }
    }

    // !for country
    if (country?.id) {
      try {
        const response = await privateRequest.post(`/api/quota/country-phone-analysis/`, body);
        setExistsSeries([response?.data?.CountryCount, response?.data?.DialCount]);
        setMissingSeries([
          response?.data?.RawCount - response?.data?.CountryCount,
          response?.data?.RawCount - response?.data?.DialCount,
        ]);
        setCountryChartError('');
      } catch (error) {
        console.log(error);
        setCountryChartError('some error occured');
      }
    }
  };

  return (
    <Stack width={'100%'} direction={'column'} alignItems={'flex-start'} flexWrap={'wrap'} sx={{ marginTop: '8px' }}>
      {' '}
      <Button
        aria-label="expand row"
        size="small"
        onClick={() => setIsFilterationModalVisible(!isFilterationModalVisible)}
      >
        {isFilterationModalVisible ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        Apply Filters
      </Button>
      <Collapse
        in={isFilterationModalVisible}
        timeout="auto"
        unmountOnExit
        sx={{
          marginTop: '10px',
          width: '100%',
        }}
      >
        <Stack direction={'row'} gap={'20px'} flexWrap={'wrap'} alignItems={'stretch'}>
          <Box sx={{ width: '100%' }}>
            <TagsInput keyWords={keyWords} selectedKeywords={selectedKeywords} />
          </Box>
          <Stack sx={{ width: '100%' }} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
            <CountryDropdown
              onInputChange={onInputChange}
              onChange={onChange}
              inputValue={inputValue}
              selectedCountry={country}
            />
            <Button
              variant="contained"
              style={{ height: '56px', maxWidth: '200px', width: '100%' }}
              size="small"
              onClick={() => filter(requestId)}
            >
              Filter
            </Button>
          </Stack>
        </Stack>

        <Stack direction={'row'} gap={'50px'} flexWrap={'wrap'} alignItems={'flex-end'} justifyContent={'center'}>
          {keyWordChartError ? (
            <></>
          ) : (
            <Stack direction={'column'} gap={'5px'} flexWrap={'wrap'} alignItems={'center'}>
              <Chart options={optionsKeywords} series={series} type="radialBar" height={250} />
              <Typography>Keyword Filteration</Typography>
            </Stack>
          )}

          {countryChartError ? (
            <></>
          ) : (
            <Stack direction={'column'} gap={'5px'} flexWrap={'wrap'} alignItems={'center'}>
              {existsSeries && <Chart options={optionsCountry} series={seriesCountry} type="bar" height={250} />}
              <Typography>Country Filters</Typography>
            </Stack>
          )}
        </Stack>
      </Collapse>{' '}
    </Stack>
  );
};
export default FilterComponent;
