import { Alert, AlertTitle, Snackbar } from '@mui/material';

const Popup = ({ modalTitle, modalMessage, modalType, isModalOpen, handleModalClose, autoHideDuration }) => {
  return (
    <Snackbar open={isModalOpen} autoHideDuration={autoHideDuration || 4000} onClose={handleModalClose}>
      <Alert variant="filled" onClose={handleModalClose} severity={modalType} sx={{ width: '100%' }}>
        <AlertTitle>{modalTitle}</AlertTitle>
        {modalMessage ? <strong>{modalMessage}</strong> : <></>}
      </Alert>
    </Snackbar>
  );
};
export default Popup;
