import { useState, useEffect, useCallback } from 'react';
// mui
import { Stack, TextField, Chip } from '@mui/material';
//import uuid v4
import { v4 as uuid } from 'uuid';

const TagsInput = ({ selectedKeywords, keyWords }) => {
  const [tags, setTags] = useState([]);
  const [tagValue, setTagValue] = useState('');

  useEffect(() => {
    selectedKeywords(tags);
  }, [tags]);

  useEffect(() => {
    setTags(keyWords);
  }, []);

  const handleDelete = useCallback(
    (tagId) => {
      const newtags = tags.filter((tag) => tag?.id !== tagId);
      setTags(newtags);
    },
    [tags]
  );

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setTags((prevTags) => [
        ...prevTags,
        {
          id: uuid(),
          tag: tagValue,
        },
      ]);
      setTagValue('');
    },
    [tagValue]
  );

  return (
    <form sx={{ width: '100%', display: 'flex', flexBasis: '100%' }} onSubmit={handleOnSubmit}>
      <Stack flexDirection={'row'} gap={'5px'} flexWrap={'wrap'}>
        <Stack flexDirection={'row'} gap={'5px'} flexWrap={'wrap'}>
          {tags.map((data) => {
            return (
              <Chip
                color="primary"
                size="medium"
                label={data?.tag}
                variant="outlined"
                onDelete={() => handleDelete(data?.id)}
                key={data?.id}
              />
            );
          })}
        </Stack>
        <TextField
          fullWidth
          variant="standard"
          size="small"
          sx={{ margin: '1rem 0' }}
          placeholder={'Enter tags'}
          value={tagValue}
          onChange={(e) => setTagValue(e.target.value)}
        />
      </Stack>
    </form>
  );
};

export default TagsInput;
