import { useState } from 'react';
// react icons
import { BiTime } from 'react-icons/bi';
import { ImLocation } from 'react-icons/im';
// mui
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
// functions and utils
import { downloadExcelFile, renderExcelFile } from '../../utils/excelUtils';
import { formatTime } from '../../utils/formatTime';
// components
import Popup from '../popup/Popup';
import FilterComponent from '../filters/Filter';
import DataPreviewTable from '../data-table/DataTable';

const UserHistoryRecord = ({ history }) => {
  const {
    request_id: requestId,
    query,
    status,
    requested_places_count: requestedPlacesCount,
    fetched_places_count: fetchedPlacesCount,
    request_time: requestTime,
    response_time: responseTime,
  } = history;

  const [toggleDataPreview, setToggleDataPreview] = useState(false);
  const [isLoadingFilePreview, setIsLoadingFilePreview] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const [previewError, setPreviewError] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);

  const handleExcelDownload = async () => {
    setDownloadError(false);
    if (fetchedPlacesCount <= 0) return setDownloadError(true);

    const { success } = await downloadExcelFile(requestId, query);
    if (!success) {
      setDownloadError(true);
    }
  };

  const handleExcelPreview = async () => {
    setPreviewError(false);

    if (fetchedPlacesCount <= 0) return setPreviewError(true);

    if (toggleDataPreview) {
      setToggleDataPreview(false);
    } else {
      setIsLoadingFilePreview(true);
      const response = await renderExcelFile(requestId);
      setIsLoadingFilePreview(false);
      if (response?.success) {
        setFetchedData(response?.data);
        setToggleDataPreview(true);
      }
      if (!response?.success) {
        setPreviewError(true);
      }
    }
  };

  return (
    <>
      {/* download error popup */}
      <Popup
        modalTitle={'Download Error'}
        modalMessage={'Some error occurred while downloading File'}
        modalType={'error'}
        isModalOpen={downloadError}
        handleModalClose={() => setDownloadError(false)}
      />
      {/* preview error popup */}
      <Popup
        modalTitle={'Preview Error'}
        modalMessage={'Could not Preview Data'}
        modalType={'error'}
        isModalOpen={previewError}
        handleModalClose={() => setPreviewError(false)}
      />

      <Card title={query} key={requestId} sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h4" textTransform={'capitalize'}>
            {query} &nbsp; {status === 'Pending' && <CircularProgress size={20} />}
          </Typography>
          {status === 'Success' ? (
            <>
              {/* heading, stats and buttons */}
              <Stack
                direction={'row'}
                gap={'10px'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                {/* heading and stats */}
                <Stack direction={'row'} gap={'10px'} flexWrap={'wrap'}>
                  <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                    <ImLocation /> {requestedPlacesCount}/{fetchedPlacesCount} Places Fetched
                  </Stack>
                  <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                    <BiTime /> {formatTime(requestTime, responseTime)}
                  </Stack>
                </Stack>
                {/* buttons */}
                <Stack direction={'row'} gap={'10px'} alignItems={'center'} flexWrap={'wrap'}>
                  <Button variant="contained" size="small" onClick={handleExcelDownload}>
                    Download File
                  </Button>

                  <LoadingButton
                    loading={isLoadingFilePreview}
                    size="small"
                    variant="contained"
                    onClick={handleExcelPreview}
                  >
                    Preview Data
                  </LoadingButton>
                </Stack>
                {toggleDataPreview && !isLoadingFilePreview && (
                  <DataPreviewTable
                    requestId={requestId}
                    query={query}
                    data={fetchedData}
                    isLoading={isLoadingFilePreview}
                  />
                )}
              </Stack>

              {/* filters collapse element */}
              {!!fetchedPlacesCount && <FilterComponent requestId={requestId} />}
            </>
          ) : status === 'Pending' ? (
            <>
              <Typography textTransform={'capitalize'}>
                We are processing your request, Please have some patience..
              </Typography>
            </>
          ) : (
            <Typography textTransform={'capitalize'}>Something went wrong</Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default UserHistoryRecord;
