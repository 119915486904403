import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Alert, AlertTitle, Container, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { searchLeads, selectSearchLeadStatus, selectSearchLeadError } from '../redux/search/searchSlice';
import { selectUserHistoryData } from '../redux/history/historySlice';
// components
import SearchTable from '../sections/@search-leads/Table';
import CountryDropdown from '../components/country-dropdown/CountryDropdown';
import UserHistoryRecord from '../components/user-history/UserHistoryRecord';

const SearchPage = () => {
  const [country, setCountry] = useState({
    label: 'United States',
    id: 'US',
    dialCode: '+1',
  });
  const [inputValue, setInputValue] = useState('');

  const dispatch = useDispatch();
  const isSearchingPlaces = useSelector(selectSearchLeadStatus);
  const userHistory = useSelector(selectUserHistoryData);
  const searchError = useSelector(selectSearchLeadError);

  const isMdOrSmaller = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const onChange = (_, newValue) => {
    // this sets country
    setCountry(newValue);
  };

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const validationSchema = Yup.object().shape({
    searchTerm: Yup.string('Please enter valid value')
      .min(5, 'Search Term should atleast be 5 characters')
      .required('Search Term is required'),
    searchCount: Yup.number('Please enter a valid number')
      .min(2, 'Search count should atleast be 2')
      .required('Search count is required'),
  });

  const formik = useFormik({
    initialValues: {
      searchTerm: '',
      searchCount: 2,
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        region: country?.id || 'US',
      };
      await dispatch(searchLeads(data));
    },
  });

  return (
    <>
      <Helmet>
        <title>SearchPage | ProspectPug </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          SearchPage
        </Typography>

        {isSearchingPlaces === 'search-error' && (
          <Alert sx={{ marginBottom: '30px' }} severity="error">
            <AlertTitle>Search Error</AlertTitle>
            <strong>{searchError}</strong>
          </Alert>
        )}

        <form
          onSubmit={formik.handleSubmit}
          style={{ marginBottom: '50px', display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}
        >
          <Stack gap={1} direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, sm: 2 }}>
            <TextField
              error={!!formik.errors.searchTerm}
              helperText={formik.errors.searchTerm}
              value={formik.values.searchTerm}
              onChange={formik.handleChange}
              name="searchTerm"
              type="text"
              fullWidth
              size="large"
              label="Search"
            />

            <Stack direction={{ sm: 'row' }} spacing={{ xs: 1, sm: 2 }} sx={{ flexGrow: 1 }}>
              <TextField
                error={!!formik.errors.searchCount}
                helperText={formik.errors.searchCount}
                value={formik.values.searchCount}
                onChange={formik.handleChange}
                type="number"
                name="searchCount"
                fullWidth={isMdOrSmaller}
                label="Places to fetch"
                sx={{ flexBasis: isMdOrSmaller ? '100%' : 'auto' }}
                size="large"
              />
            </Stack>
          </Stack>

          <Stack sx={{ width: '100%' }} flexDirection={'row'} gap={1} flexWrap={'wrap'}>
            <CountryDropdown
              onChange={onChange}
              onInputChange={onInputChange}
              selectedCountry={country}
              inputValue={inputValue}
            />

            <LoadingButton
              loading={isSearchingPlaces === 'searching'}
              color="primary"
              style={{ height: '56px', maxWidth: '200px', width: '100%' }}
              type="submit"
              variant="contained"
            >
              Search Leads
            </LoadingButton>
          </Stack>
        </form>

        <SearchTable />

        {/* show 5 recent searches */}
        <Stack sx={{ mb: 3 }} direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'} gap={2}>
          <Typography variant="h4">Your Previous Searches</Typography>
          <Link to="/dashboard/history">See all Searches</Link>
        </Stack>
        {userHistory?.user_requests?.slice(0, 5)?.map((history) => (
          <UserHistoryRecord history={history} key={history?.request_id} />
        ))}
      </Container>
    </>
  );
};

export default SearchPage;
