import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export const formatTime = (startTime, endTime) => {
  const RequestTime = new Date(startTime);
  const ResponseTime = new Date(endTime);

  const ms = ResponseTime - RequestTime; // difference in milliseconds
  // return timeDiff;

  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));

  const formattedTime = [];
  if (days > 0) formattedTime.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) formattedTime.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes > 0) formattedTime.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  if (seconds > 0) formattedTime.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  if (formattedTime.length === 0) {
    return '0 second';
  }

  return formattedTime.join(' ');
};
