import { read, utils } from 'xlsx';
// utils and configs
import { privateRequest } from './apiRequest';
import { BASE_URL } from '../config/variables';

export const downloadExcelFile = async (requestId, searchTerm) => {
  try {
    const response = await privateRequest.get(`/api/download/${requestId}`, { responseType: 'blob' });

    const blob = await response.data;

    if (blob) {
      const fileName = `${searchTerm}-${requestId}.xlsx`;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.id = `${requestId}-${searchTerm}`;
      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove(); // afterwards, remove the element
      return { success: true };
    }
  } catch (error) {
    return { error, success: false };
  }
};

export const renderExcelFile = async (requestId) => {
  try {
    const response = await (await fetch(`${BASE_URL}/api/download/${requestId}`)).arrayBuffer();
    // ! array buffer not working in case of axios
    // const response = await privateRequest.get(`/api/download/${requestId}`, { responseType: 'arraybuffer' });
    const wb = await read(response);
    const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    return { data, success: true };
  } catch (error) {
    return { error, success: false };
  }
};
