import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import otherService from './otherService';
import { GENERIC_ERROR_MESSAGE } from '../variables';

const initialState = {
  countries: {
    data: [],
    status: 'idle',
    error: null,
  },
};

export const getCountries = createAsyncThunk('others/get-countries', async (thunkAPI) => {
  try {
    const response = await otherService.getCountries();
    return response?.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

const otherSlice = createSlice({
  initialState,
  name: 'others',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.countries.status = 'fetching-countries';
        state.countries.error = null;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries.status = 'fetched-countries';
        state.countries.data = action.payload.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        state.countries.error = null;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.countries.status = 'error-fetching-countries';
        state.countries.error = action?.payload || GENERIC_ERROR_MESSAGE;
      });
  },
});

export const selectCountries = (state) => state.others.countries.data;

export default otherSlice.reducer;
