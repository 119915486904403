// react
import { useEffect, useMemo, useState } from 'react';
// packages
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
// packages - mui
import {
  Box,
  Card,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
// redux
import { getUserHistory, selectUserHistoryData, selectUserHistoryStatus } from '../redux/history/historySlice';
// icons
import { FiSearch } from 'react-icons/fi';
import { BiDollar, BiError } from 'react-icons/bi';
import { MdPlace } from 'react-icons/md';
// components
import AppWidgetSummary from '../sections/@dashboard/app/AppWidgetSummary';
import UserHistoryRecord from '../components/user-history/UserHistoryRecord';

const History = () => {
  const dispatch = useDispatch();

  const [statusFilterValue, setStatusFilterValue] = useState('all');
  const [filteredArr, setFilteredArr] = useState([]);

  const userHistory = useSelector(selectUserHistoryData);
  const userHistoryStatus = useSelector(selectUserHistoryStatus);

  useEffect(() => {
    dispatch(getUserHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const status = useMemo(
    () => [
      {
        value: 'all',
        label: 'All',
      },
      {
        value: 'pending',
        label: 'Pending',
      },
      {
        value: 'success',
        label: 'Success',
      },
    ],
    []
  );

  const changeHistoryStatus = (e) => setStatusFilterValue(e.target.value);

  useEffect(() => {
    let filteredResults = [];
    if (statusFilterValue === 'pending') {
      filteredResults = userHistory?.user_requests?.filter((request) => request?.status === 'Pending');
    }
    if (statusFilterValue === 'success') {
      filteredResults = userHistory?.user_requests?.filter((request) => request?.status === 'Success');
    }
    if (statusFilterValue === 'all') {
      filteredResults = userHistory?.user_requests;
    }
    setFilteredArr(filteredResults);
  }, [statusFilterValue, userHistory?.user_requests]);

  let content;

  if (userHistoryStatus === 'fetching-user-history') {
    content = (
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Card sx={{ marginTop: '30px', textAlign: 'center', padding: '20px 30px' }}>
          <CircularProgress size={60} />
          <Typography variant="h5" sx={{ marginTop: '10px' }}>
            Fetching your search records
          </Typography>
        </Card>
      </Stack>
    );
  }

  if (userHistoryStatus === 'fetched-user-history') {
    if (userHistory?.user_requests) {
      content = (
        <>
          <Grid container spacing={3} sx={{ mb: 5 }}>
            {userHistory?.user_requests && (
              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary
                  title="Total Requests made"
                  total={userHistory?.user_requests?.length}
                  icon={FiSearch}
                />
              </Grid>
            )}

            {userHistory?.user_requests && (
              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary
                  title="Total Places Fetched"
                  total={userHistory?.user_requests
                    ?.filter((d) => d.status === 'Success')
                    .reduce((acc, history) => acc + history?.fetched_places_count || 0, 0)}
                  color="warning"
                  icon={MdPlace}
                />
              </Grid>
            )}

            {userHistory?.user_requests && (
              <Grid item xs={12} sm={6} md={4}>
                <AppWidgetSummary title="Total Bill" total={1201} color="info" icon={BiDollar} />
              </Grid>
            )}
          </Grid>

          <Stack
            justifyContent={{ sm: 'space-between', md: 'space-between' }}
            alignItems={{ sm: '', md: 'flex-end' }}
            direction={{ sm: 'column', md: 'row' }}
            gap={2}
            mb={4}
          >
            <Typography
              variant="h4"
              //  sx={{ mb: 5 }}
            >
              Your Search Records
            </Typography>

            {/* dropdown to select either all, pending and fullfilled */}
            <Box minWidth={{ md: '200px' }} maxWidth={{ md: '200px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Task Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={statusFilterValue}
                  label="Task Status"
                  onChange={changeHistoryStatus}
                >
                  {status?.map((st) => (
                    <MenuItem key={st.value} value={st.value}>
                      {st.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Stack>

          {filteredArr?.map((history) => (
            <UserHistoryRecord history={history} key={history?.request_id} />
          ))}
        </>
      );
    } else {
      content = <AppWidgetSummary title="No records found" color="error" icon={BiError} />;
    }
  }

  if (userHistoryStatus === 'error-fetching-user-history') {
    content = (
      <Stack justifyContent={'center'} alignItems={'center'}>
        <Card sx={{ marginTop: '30px', textAlign: 'center', padding: '20px 30px' }}>
          {/* <CircularProgress size={60} /> */}
          <Typography variant="h5" sx={{ marginTop: '10px' }}>
            Error Fetching Your Search Records History
          </Typography>
        </Card>
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
        <title>History | ProspectPug </title>
      </Helmet>

      <Container maxWidth="xl">{content}</Container>
    </>
  );
};

export default History;
