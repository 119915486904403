import { configureStore } from '@reduxjs/toolkit';
// slices
import authSlice from './auth/authSlice';
import searchSlice from './search/searchSlice';
import historySlice from './history/historySlice';
import profileSlice from './profile/profileSlice';
import otherSlice from './other/otherSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    search: searchSlice,
    history: historySlice,
    profile: profileSlice,
    others: otherSlice,
  },
});

export default store;
