import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// mui
import { Button, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
// redux
import { setSearchStatusToSuccess } from '../../redux/search/searchSlice';
// configs and utils
import { BASE_URL } from '../../config/variables';
import { downloadExcelFile } from '../../utils/excelUtils';
// components
import Filter from '../filters/Filter';

const SearchLead = ({ requestId, searchTerm }) => {
  const [isGeneratingFile, setIsGeneratingFile] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('SSE : called');
    const evtSource = new EventSource(`${BASE_URL}/api/stream/${requestId}`);
    evtSource.addEventListener('new_message', (event) => {
      setIsGeneratingFile(true);
      console.log('SSE : ', event.data);
      // Logic to handle status updates
      // setMessage((messages) => [...messages, event.data]);
    });

    evtSource.addEventListener('end_event', (event) => {
      // setMessage((messages) => [...messages, event.data]);
      setIsGeneratingFile(false);
      console.log(event);
      const requestId = event?.currentTarget?.url?.split('/').reverse()[0];
      console.log('requestId', requestId);
      dispatch(setSearchStatusToSuccess(requestId));
      //   setStatus(true)
      evtSource.close();
    });

    return () => {
      evtSource.close();
    };
  }, []);

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" textTransform={'capitalize'} gutterBottom>
            {searchTerm} &nbsp; {isGeneratingFile && <CircularProgress size={20} />}
          </Typography>

          {isGeneratingFile ? (
            <Typography>Please wait while we are processing your request...</Typography>
          ) : (
            <Stack gap={2} direction={'row'} flexWrap={'wrap'}>
              <Button variant="contained" onClick={() => downloadExcelFile(requestId, searchTerm)}>
                Download File
              </Button>

              {/*   */}
              <Filter requestId={requestId} />
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SearchLead;
