import axios from 'axios';
import { BASE_URL } from '../config/variables';
import { isTokenExpired } from './checkTokenValidity';
import { logOutUser } from 'src/redux/auth/authSlice';

const axiosConfig = {
  baseURL: BASE_URL,
  withCredentials: true,
};

let store;
export const injectStore = (_store) => {
  store = _store;
};

export const publicRequest = axios.create(axiosConfig);
export const privateRequest = axios.create(axiosConfig);

privateRequest.interceptors.request.use(async (config) => {
  const token = store?.getState()?.auth?.currentUser?.access_token;
  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept = 'application/json';

  const tokenExpiryResponse = isTokenExpired({ access_token: token });

  // Check if token expired
  if (token && tokenExpiryResponse && tokenExpiryResponse !== 'no-token') {
    console.log('Token expired.... ');
    try {
      store.dispatch(logOutUser());
    } catch (error) {
      console.log(error);
    }
  }
  return config;
});
