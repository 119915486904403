import { useEffect, useState } from 'react';
// packages
import DataTable from 'react-data-table-component';
import { Anchorme } from 'react-anchorme';
// mui
import { Box, Button, CircularProgress, TextField } from '@mui/material';

const DataPreviewTable = ({ query, data, isLoading }) => {
  const [dataTableColumns, setDataTableColumns] = useState([]);
  const [filterText, setFilterText] = useState('');

  const sortFunction = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (typeof aField === 'string' && typeof bField === 'string') {
        comparison = aField.toLowerCase().localeCompare(bField.toLowerCase());
      } else {
        comparison = aField < bField ? -1 : aField > bField ? 1 : 0;
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  const filteredItems = data.filter((item) => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

  const subHeaderComponentMemo = (
    <TextField
      name="filter-data-columns"
      placeholder="Filter Names"
      label="Filter Names"
      type="search"
      onChange={(e) => setFilterText(e.target.value)}
      value={filterText}
    />
  );

  useEffect(() => {
    if (data.length > 0) {
      const keys = Object.keys(data[0]);
      setDataTableColumns(
        keys
          .filter((tableHead) => tableHead !== 'query')
          .map((key) => {
            return {
              name: key?.split('_')?.join(' ').toLocaleUpperCase(),
              selector: (row) => row[key],
              sortable: true,
            };
          })
      );
    }
  }, [data]);

  const DetailsComponent = ({ data }) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        {/* checkbox */}
        <div
          // className="jICeoa"
          style={{
            flex: '0 0 48px',
            minWidth: '48px',
            WebkitBoxPack: 'center',
            justifyContent: 'center',
            WebkitBoxAlign: 'center',
            alignItems: 'center',
            userSelect: 'none',
            whiteSpace: 'nowrap',
          }}
        ></div>
        {/* expand icon */}
        <div
          // className="cLPfJ"
          style={{ whiteSpace: 'nowrap', fontWeight: '400', minWidth: '48px', flex: '0 0 48px' }}
        ></div>
        {/* other rows */}
        {Object.keys(data.data)
          .filter((tableHead) => tableHead !== 'query')
          .map((key, index) => (
            <Box key={index} className="gSVZoG dpxigv">
              <Anchorme target="_blank" rel="noreferrer noopener">
                {data.data[key]}
              </Anchorme>
            </Box>
          ))}
      </div>
    );
  };

  const exportToExcel = (e) => {
    // TODO: future features
  };

  const actionsMemo = <Button onClick={exportToExcel}>Export To Excel</Button>;

  return (
    <DataTable
      actions={actionsMemo}
      title={query?.slice(0, 1)?.toLocaleUpperCase() + query?.slice(1, query?.length)}
      sortFunction={sortFunction}
      columns={dataTableColumns}
      data={filteredItems}
      pagination
      fixedHeader
      highlightOnHover
      pointerOnHover
      progressPending={isLoading}
      progressComponent={<CircularProgress size={20} />}
      dense
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign="left"
      selectableRows
      persistTableHead
      responsive
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={(d) => <DetailsComponent data={d} />}
    />
  );
};

export default DataPreviewTable;
