import { useSelector } from 'react-redux';
// mui
import { Autocomplete, TextField } from '@mui/material';
// redux
import { selectCountries } from '../../redux/other/otherSlice';

export default function CountryDropdown({ selectedCountry, onChange, inputValue, onInputChange }) {
  const countries = useSelector(selectCountries);

  const countriesDropdownData = countries?.map((country) => ({
    label: country?.name,
    id: country?.code,
    dialCode: country?.dial_code,
  }));

  return (
    <Autocomplete
      sx={{ mb: 2, width: '100%', maxWidth: 300 }}
      // sx={{ mb: 2 }}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onChange}
      value={selectedCountry}
      disablePortal
      id="country-dropdown"
      options={countriesDropdownData}
      renderInput={(params) => <TextField {...params} label="Countries" />}
      isOptionEqualToValue={(d) => d.id}
    />
  );
}
