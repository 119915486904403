import { publicRequest } from '../../utils/apiRequest';

const getCountries = async () => {
  const response = await publicRequest.get(`/api/countries`);
  return response;
};

const otherServices = { getCountries };

export default otherServices;
